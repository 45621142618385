// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

html, body {
	width: 100%;
	height: 100%;
}

body {
  // overflow-x: hidden;
}

ul {
	list-style: none;
}

ul, li, p, figure, h2, h3 {
	margin: 0;
	padding: 0;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 

/**
 * Basic styles for links
 */
a {
  // text-decoration: none;
  
  &:link, &:hover, &:visited {
  	text-decoration: none;
  	color: #000;
  }

  @include on-event {
    // text-decoration: underline;
  }
}
