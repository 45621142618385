// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'aaregular';
  src:  url('../fonts/alteweb.woff2') format('woff2'),
        url('../fonts/alteweb.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aabold';
  src:  url('../fonts/aa-bold-webfont.woff2') format('woff2'),
        url('../fonts/aa-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}