.label, .list__container h4 {
	position: absolute;
	display: inline-block;
	left: 0;
	top: 0;
	padding: 3px 6px 2px 5px;

	font-family: $font-stack;
	font-size: 12px;
	font-weight: normal;
	color: #fff;
	background-color: #000;
}