// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
	position: relative;
	z-index: 3;

	a {
		cursor: pointer;
	}
}

.logo {
	position: fixed;
	top: 10px;
	width: 150px;
	left: 50%;
	margin-left: -75px;

	font-size: 62px;
	font-family: $font-stack;
	letter-spacing: 3px;
	text-align: center;
	color: #000;
	z-index: 1;
}

.menu {
	position: relative;
	z-index: 2;
	margin-top: 34px;

	ul {
		list-style: none;
	}
}

.menu__item, .menu__logo {
	display: inline-block;
	position: relative;
	padding: 0 $component-padding;
}

.menu__item {
	float: right;
	font-size: 16px;
}

.menu__item--active:after {
	height: 2px;
}

.menu__logo {
	float: left;
}

@media all and (max-width: $bp-mobile) {
	.logo {
		top: 8px;
		font-size: 45px;
	}

	.menu {
		margin-top: 22px;
	}

	.menu__logo {
		display: none;
	}

	.menu__item {
		float: left;
	}

	.menu__item + .menu__item {
		float: right;
	}
}