.close {
	position: relative;
	width: 20px;
	height: 20px;
	background-color: #000;
	cursor: pointer;

	&:before, &:after {
		content: '';
		position: absolute;
		top: 3px;
		left: 9px;
		width: 1px;
		height: 13px;
		background-color: #fff;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}