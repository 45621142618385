// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

.vertically-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.no-scroll {
  // overflow-y: ;

  &::-webkit-scrollbar { 
    display: none; 
  }
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden { 
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


/**
 * Basic wrapper class
 */
.wrapper {
  width: 100%;
  padding: 0 $window-margin;
}

@media all and (max-width: $bp-mobile) {
  .wrapper {
    padding: 0 $mobile-margin;
  }
}

.wrapper__inner {
  width: 100%;
}


/**
 * Add a basic underline to element
 */
.u, .list__container a, .list__container strong, .popup__description a {
  position: relative;
  text-decoration: none;
}

.u, .list__container a, .list__container strong, .popup__description a {
  position: relative;
  font-weight: normal;

  &:after {
    content: '';
    position: absolute;
    right: $component-padding;
    bottom: 0;
    left: $component-padding;
    height: 1px;
    background-color: #000;
  }

  &:hover:after {
    height: 2px;
  }
}

.popup__description a {
  &:after {
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.list__container a, .list__container strong {
  &:after {
    left: 0;
    right: 0;
    height: 2px;
  }
}

.u--bold:after {
  height: 2px;
}