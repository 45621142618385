.popup__outer {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	cursor: pointer;
	z-index: 4;

	&:after {
		content: '';
		position: relative;
		height: 10px;
	}
	// background-color: rgba(255,255,255,0.4);
}


.popup__inner {
	position: absolute;
	top: 34px;
	left: 51px;
	right: 51px;
	margin: 0 auto;
	// width: 100%;
	max-width: 1250px;

	cursor: auto;

	transform: translate3d(0,2000px,0);
	transition: transform 0.4s ease;

	&.loaded {
		transform: translate3d(0,0,0);
	}
}

.popup__container {
	background-color: #fff;
}

.popup__description {
	float: left;
	width: 66.667%;
	padding: 25px $component-padding 5px $component-padding;

	p {
		margin-bottom: 27px;
		font-size: 22px;
		line-height: 27px;
	}
}

.popup__img {
	display: block;
	margin-bottom: 8px;
	width: 100%;
	height: auto;
	padding-top: 66%;

	opacity: 0;
	transition: opacity 0.2s ease;

	&.loaded {
		padding-top: 0;
		opacity: 1;
	}
}

.popup__close {
	position: absolute;
	right: 0;
	top: 0;
}

.popup__suffix {
	float: right;
	padding: 0 $component-padding;
	width: 33.33%;
}

@media all and (max-width: $bp-medium) {
	.popup__description, .popup__suffix {
		display: block;
		float: none;
		width: 100%;
	}

	.popup__description {
		padding-right: 16px;
	}
}

@media all and (max-width: $bp-mobile) {
	.popup__inner {
		left: 16px;
		right: 16px;
		// padding: 0 16px;
	}

	.popup__description {
		p {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 12px;
		}
	}

	.popup__suffix {
		padding: 21px 0 0;
	}
}