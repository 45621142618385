/**
 * Basic typography style for copy text
 */
body, h1 {
  color: #000;
  font-family: $font-stack;
}

h1 {
	margin: 0;
	padding: 0;
	font-weight: inherit;
	font-size: 16px;
}
