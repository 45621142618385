.list__container {
	position: relative;
	padding-top: 23px;
	margin-bottom: 56px;

	h4 {
		position: absolute;
		left: 0;
		top: 0;
		margin: 0;
	}

	li {
		font-size: 22px;
		line-height: 27px;
	}
}

// .list {
// 	.u:after {
//     right: 0;
//     left: 0;
//     bottom: 1px;
//   }
// }

@media all and (max-width: $bp-mobile) {
	.list__container {
		margin-bottom: 28px;

		li {
			padding: 0 $component-padding;
			font-size: 16px;
			line-height: 22px;
		}
	}

	// .list {
	// 	.u:after {
	// 		bottom: 0;
	// 	}
	// }
}