.thumb {
	display: block;
	position: relative;
	min-height: 300px;
	opacity: 0;
	overflow: hidden;

	cursor: pointer;
	transition: opacity 0.3s ease;

	&:hover {
		.thumb__caption {
			opacity: .999;
		}
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		border: 4px solid #fff;
		z-index: 1;
	}

	&.loaded {
		min-height: 1px;
		opacity: 1;
	}
}

.thumb--small {
	width: 33.333%;
}

.thumb--large {
	width: 66.66%;
}


.thumb__img {
	display: block;
	height: auto;
	width: 100%;
}

.thumb__caption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 2;
	opacity: 0;

	transition: opacity 0.35s ease;

	&:before {
		content: '';
		position: absolute;
		top: $component-padding;
		right: $component-padding;
		bottom: $component-padding;
		left: $component-padding;
		background-color: rgba(0,0,0, 0.75);
	}

	h2 {
		font-family: $font-stack;
		font-weight: normal;
		display: inline-block;
		width: auto;
		padding: 6px 11px 3px;

		font-size: 24px;
		background-color: #fff;

		transition: opacity 0.55s ease;
	}
}

@media all and (max-width: 1280px) {
	.thumb__caption {
		h2 {
			font-size: 24px;
		}
	}
}

@media all and (max-width: $bp-medium) {
	.thumb--small, .thumb--large {
		width: 50%;
	}
}

@media all and (max-width: $bp-mobile) {
	.thumb--small, .thumb--large {
		width: 100%;
	}
}